/* eslint-disable max-lines-per-function */
import { useCallback, useMemo } from 'react';

import { useI18n } from '@change/core/react/i18n';
import { useNavigate } from '@change/core/react/router';
import { useTracking } from '@change/core/react/tracking';
import { useUtilityContext } from '@change/core/react/utilityContext';

import {
	useMembershipAwarenessFormData,
	useMembershipAwarenessFormFcm,
	useMembershipAwarenessFormFcmExperiments,
} from '../../../../formContext';
import type { Props } from '../../../../shared/types';
import { getSubscriptionAmount } from '../../../../shared/utils/getSubscriptionAmount';

type Result = ModelHookResult<
	{
		buttonAmounts: ReadonlyArray<{ amount: number; amountLocalized: string }>;
		selectedAmount: { amount: number; amountLocalized: string };
		buttonText?: string;
		displayPayWithText: boolean;
	},
	{
		onClick: (amount?: number) => void;
	}
>;

export function useAmountSelector({ sourceLocation, slug }: Props): Result {
	const track = useTracking();
	const navigate = useNavigate();
	const { amountToBaseUnits, translate } = useI18n();
	const {
		subscriptionsAmounts: buttonAmounts,
		subscriptionsDefaultAmount: defaultAmount,
		currency,
	} = useMembershipAwarenessFormData();
	const { subscriptionConfig } = useMembershipAwarenessFormFcm();
	const utilityContext = useUtilityContext();
	const { defaultAmountExperiment, membershipAmountsButtonOptionsExperiment } =
		useMembershipAwarenessFormFcmExperiments();

	const buttonAmountOptions = useMemo(() => {
		if (membershipAmountsButtonOptionsExperiment.variation === 'control') return buttonAmounts;
		const amounts =
			membershipAmountsButtonOptionsExperiment.fcm.config &&
			(membershipAmountsButtonOptionsExperiment.variation === 'variant_1' ||
				membershipAmountsButtonOptionsExperiment.variation === 'variant_2')
				? membershipAmountsButtonOptionsExperiment.fcm.config[membershipAmountsButtonOptionsExperiment.variation]
						.amounts
				: undefined;
		return amounts
			? amounts.map((amount) =>
					getSubscriptionAmount(
						{
							amount,
							currency,
							currencyDisplay: 'narrowSymbol',
							amountInBaseUnits: false,
						},
						utilityContext,
					),
				)
			: buttonAmounts;
	}, [buttonAmounts, currency, utilityContext, membershipAmountsButtonOptionsExperiment]);

	const selectedAmount = useMemo(() => {
		const setDefaultAmount =
			membershipAmountsButtonOptionsExperiment.fcm.config &&
			(membershipAmountsButtonOptionsExperiment.variation === 'variant_1' ||
				membershipAmountsButtonOptionsExperiment.variation === 'variant_2')
				? getSubscriptionAmount(
						{
							amount:
								membershipAmountsButtonOptionsExperiment.fcm.config[membershipAmountsButtonOptionsExperiment.variation]
									.defaultAmount,
							currency,
							currencyDisplay: 'narrowSymbol',
							amountInBaseUnits: false,
						},
						utilityContext,
					)
				: defaultAmount;
		return defaultAmountExperiment.variation === 'variant' ? buttonAmountOptions[2] : setDefaultAmount;
	}, [
		membershipAmountsButtonOptionsExperiment.fcm.config,
		membershipAmountsButtonOptionsExperiment.variation,
		currency,
		utilityContext,
		defaultAmount,
		defaultAmountExperiment.variation,
		buttonAmountOptions,
	]);

	const onClick = useCallback(
		(amount?: number) => {
			void track(`${sourceLocation}_membership_ask_click`);
			if (subscriptionConfig?.thirdPartySubscriptionService) {
				void track('membership_ask_click_third_party_subscription_service');
				navigate(subscriptionConfig?.thirdPartySubscriptionService);
				return;
			}
			const path = slug ? `/p/${encodeURIComponent(slug)}/psf/membership` : '/member/join';
			navigate(
				`${path}?source_location=${sourceLocation}&amount=${amountToBaseUnits(amount ?? selectedAmount.amount, currency)}`,
			);
		},
		[track, sourceLocation, slug, navigate, amountToBaseUnits, selectedAmount.amount, currency, subscriptionConfig],
	);

	return {
		data: {
			selectedAmount,
			buttonAmounts: buttonAmountOptions,
			buttonText: translate('corgi.membership.awareness.cta'),
			displayPayWithText: !subscriptionConfig?.thirdPartySubscriptionService,
		},
		actions: {
			onClick,
		},
	};
}
