import type { UtilityContext } from '@change/core/react/utilityContext';

type Params = {
	amount: number;
	currency: string;
	currencyDisplay: 'symbol' | 'narrowSymbol' | 'code' | 'name';
	amountInBaseUnits: boolean;
};

type MembershipAmount = {
	amount: number;
	amountLocalized: string;
};

export function getSubscriptionAmount(
	{ amount, currency, currencyDisplay, amountInBaseUnits }: Params,
	utilityContext: UtilityContext,
): MembershipAmount {
	return {
		amount,
		amountLocalized: utilityContext.i18n.localizeCurrency(amount, currency, {
			currencyDisplay,
			amountInBaseUnits,
		}),
	};
}
