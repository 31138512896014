import { useI18n } from '@change/core/react/i18n';

type Result = ModelHookResult<{
	headlinePart1: string;
	headlinePart2: string;
	body: string;
	ctaQuestion: string;
	heroImage: string;
}>;

export function useMembershipAwarenessPlacementConfig(): Result {
	const { translate } = useI18n();
	return {
		data: {
			headlinePart1: translate('corgi.membership.awareness.headline_part1'),
			headlinePart2: translate('corgi.membership.awareness.headline_part2'),
			body: translate('corgi.membership.awareness.description'),
			ctaQuestion: translate('corgi.membership.awareness.ask'),
			heroImage: 'https://static.change.org/membership/main-image.png',
		},
	};
}
