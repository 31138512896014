import { useEffect } from 'react';

import { useTracking as useTrackingBase } from '@change/core/react/tracking';

import { isInViewport } from 'src/app/shared/utils/dom';

type Props = {
	sourceLocation: string;
	element: React.RefObject<HTMLElement>;
};
export function useTracking({ sourceLocation, element }: Props): void {
	const track = useTrackingBase();
	useEffect(() => {
		void track(`${sourceLocation}_membership_ask_shown`);
		// only send the ask_shown event once per page load
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	useEffect(() => {
		const interval = setInterval(() => {
			if (element.current && isInViewport(element.current)) {
				void track(`${sourceLocation}_membership_ask_view`);
				clearInterval(interval);
			}
		}, 500);
		return () => {
			clearInterval(interval);
		};
	}, [track, sourceLocation, element]);
}
