import type { UtilityContext } from '@change/core/react/utilityContext';

import type { MembershipAwarenessFormData } from '../types';

import { getMembershipFormData } from './getMembershipFormData';

export async function getMembershipAwarenessFormData(
	utilityContext: UtilityContext,
): Promise<MembershipAwarenessFormData> {
	const [mainData] = await Promise.all([getMembershipFormData(utilityContext)]);

	return {
		...mainData,
	};
}
