import React from 'react';

import { TranslateHtml } from '@change/core/react/i18n';
import { Button } from '@change/design-system/components/actions';
import { Image } from '@change/design-system/components/media';
import { Box, Flex } from '@change/design-system/layout';
import { Text } from '@change/design-system/typography';

import type { Props } from '../../shared/types';

import { AmountButton } from './components/AmountButton';
import { useAmountSelector } from './hooks/useAmountSelector';

export function AmountSelector(props: Props): React.JSX.Element {
	const {
		data: { selectedAmount, buttonAmounts, buttonText, displayPayWithText },
		actions: { onClick },
	} = useAmountSelector(props);

	return (
		<Flex sx={{ flexFlow: 'column', gap: 8 }}>
			<Flex sx={{ gap: 8 }}>
				{buttonAmounts.slice(0, 3).map(({ amount, amountLocalized }) => {
					return (
						<AmountButton
							key={amount}
							amount={amount}
							amountLocalized={amountLocalized}
							isSelected={amount === selectedAmount.amount}
							onClick={() => {
								onClick(amount);
							}}
							sx={{ flex: 1 }}
						/>
					);
				})}
			</Flex>
			<Flex sx={{ gap: 8 }}>
				<AmountButton
					data-testid="membership-awareness-amount-button-third"
					amount={buttonAmounts[3].amount}
					amountLocalized={buttonAmounts[3].amountLocalized}
					isSelected={buttonAmounts[3].amount === selectedAmount.amount}
					onClick={() => {
						onClick(buttonAmounts[3].amount);
					}}
					sx={{ flex: 2 }}
				/>
				<AmountButton
					data-testid="membership-awareness-amount-button-other"
					amount={0}
					amountLocalized="0"
					isSelected={selectedAmount.amount === 0}
					onClick={() => {
						onClick(0);
					}}
					sx={{ flex: 3 }}
				/>
			</Flex>
			<Flex>
				<Button
					data-testid="membership-awareness-support-button"
					sx={{ width: '100%' }}
					onClick={() => {
						onClick();
					}}
				>
					{buttonText}
				</Button>
			</Flex>
			{displayPayWithText && (
				<Box sx={{ textAlign: 'center' }}>
					<Text>
						<TranslateHtml
							value="corgi.membership.awareness.pay_with"
							replacements={{
								paypalImageElement: (
									<Image
										alt="paypal"
										src="https://static.change.org/membership/paypal.svg"
										sx={{
											marginBottom: '-4px',
											display: 'inline',
										}}
									/>
								),
							}}
						/>
					</Text>
				</Box>
			)}
		</Flex>
	);
}
