import {
	FCM_MEMBERSHIP_AWARENESS_PLACEMENT_AB_EXPERIMENT,
	FCM_MEMBERSHIP_BUTTON_AMOUNTS_EXPERIMENT,
} from 'src/app/shared/fcm/configs';
import { createExperimentFcmConfig } from 'src/app/shared/hooks/experiment';

export const FCM_EXPERIMENT_MEMBERSHIP_AWARENESS_PLACEMENT_AB_EXPERIMENT = createExperimentFcmConfig({
	experimentName: ({ fcm: { config } }) => config?.experiment_name,
	fcmConfigs: {
		config: FCM_MEMBERSHIP_AWARENESS_PLACEMENT_AB_EXPERIMENT,
	},
	isEnabled: ({ fcm: { config } }) => {
		return !!config?.experiment_name;
	},
});

export const FCM_EXPERIMENT_MEMBERSHIP_BUTTON_AMOUNTS_EXPERIMENT = createExperimentFcmConfig({
	experimentName: ({ fcm: { config } }) => config?.experiment_name,
	fcmConfigs: {
		config: FCM_MEMBERSHIP_BUTTON_AMOUNTS_EXPERIMENT,
	},
	isEnabled: ({ fcm: { config } }) => {
		return !!config?.experiment_name;
	},
});
